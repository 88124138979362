
    import { Component, Vue, Prop } from 'vue-property-decorator';
    import {IContact} from "@/types";
    import {capitalize} from "@/helpers/commons";

    @Component({
        name: "ContactLine"
    })
    export default class ContactLine extends Vue {
        @Prop() contact!: any;

        capitalize = capitalize;

        // @ts-ignore;
        apiUrl:string = <string>process.env.VUE_APP_API_URL.replace('/1.0', '') + "/";

        get fullName() {
            return `${capitalize(this.contact.firstname)} ${capitalize(this.contact.lastname)}`;
        }

        get jobName() {
            if (this.contact.job?.jobName) {
                return capitalize(this.contact.job.jobName);
            }
            if (this.contact.position) {
                return capitalize(this.contact.position);
            }
            return capitalize(this.contact.jobName);
        }
    }
