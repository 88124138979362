
import {Component, Vue, Prop} from 'vue-property-decorator';
import {mixins} from "vue-class-component";
import ProfileContextMixin from "@/components/profileContexts/ProfileContextMixin.vue";

@Component({
    name: "ProfileVideo"
})
export default class ProfileVideo extends mixins(ProfileContextMixin) {
    get youtubeLink() {
        return this.data.video && ("https://www.youtube.com/embed/" + this.youtubeId);
    }

    get youtubeId() {
        if (this.data.video) {
            if (this.data.video.indexOf('youtube.com') !== -1) {
                if (this.data.video.indexOf("v=") !== -1) {
                    return this.data.video.split('v=')[1];
                }
                if (this.data.video.indexOf("/embed/") !== -1) {
                    return this.data.video.split('/embed/')[1];
                }
            }
            if (this.data.video.indexOf('youtu.be') !== -1) {
                return this.data.video.split('youtu.be/')[1]
            }
        }
    }
}
