
import {Component, Vue, Prop} from 'vue-property-decorator';
import ContactLine from "@/components/ContactLine.vue";
import {Getter, State} from "vuex-class";
import {IUserDB} from "@/types";
import {mixins} from "vue-class-component";
import ProfileContextMixin from "@/components/profileContexts/ProfileContextMixin.vue";

@Component({
    name: "ProfileContacts",
    components: {ContactLine}
})
export default class ProfileContacts extends mixins(ProfileContextMixin) {
    @State('collaborators') collaborators!:any

    get hasCollaborators() {
        return this.data?.collaborators?.length;
    }
    get hasContacts() {
        return !this.agency && this.data?.contacts?.length;
    }
}
