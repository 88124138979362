
import {Component, Vue, Prop} from 'vue-property-decorator';

@Component({
    name: "ProfileContextMixin"
})
export default class ProfileContextMixin extends Vue {
    @Prop() data!:any;
    @Prop() mapSize!:any;
    @Prop() descriptionSize!:any;
    @Prop(Boolean) agency!: boolean;
    @Prop() label!: string;
}
