
import {Component, Vue, Prop} from 'vue-property-decorator';
import {IAddress, IGeoLocation} from "@/types";
import {mixins} from "vue-class-component";
import ProfileContextMixin from "@/components/profileContexts/ProfileContextMixin.vue";

@Component({
    name: "ProfileMap"
})
export default class ProfileMap extends mixins(ProfileContextMixin) {
    $refs!: {
        map: any
    }
    center: IGeoLocation = {lat: 48.845617, lng: 2.2431133};

    latLng(position:any) {
        return position && {
            lat: parseFloat(<string>position[1].toString()),
            lng: parseFloat(<string>position[0].toString())
        };
    }

    get markerIcon() {
        return {
            url: require('@/assets/icons/mapMarker2.svg'),
            size: {width: 34, height: 50, f: 'px', b: 'px'},
            scaledSize: {width: 34, height: 50, f: 'px', b: 'px',}
        }
    }

    get zoom() {
        return ((this.agency ? this.mapMarkerPosition : this.mapMarkerPosition.length) && 16) || 8;
    }

    get mapMarkerPosition() {
        if (this.agency) {
            if (this.data?.address?.location) {
                return {
                    lat: parseFloat(this.data.address.location[1].toString()),
                    lng: parseFloat(this.data.address.location[0].toString())
                }
            }
        } else {
            if (this.data?.addresses?.length) {
                return this.data.addresses.map((a:any) => {
                    if (a.location?.length) {
                        return {
                            lat: parseFloat(a.location[1].toString()),
                            lng: parseFloat(a.location[0].toString())
                        }
                    }
                }).filter((a:any) => a);
            }
        }
    }

    boundMap() {
        // @ts-ignore;
        if (window.google) {
            // @ts-ignore;
            const bounds = new google.maps.LatLngBounds()
            let nbMarkers = 0;

            if (this.agency) {
                bounds.extend(this.latLng(this.data.address.location));
            } else {
                this.data?.addresses?.forEach((address: IAddress) => {
                    if (address?.location?.length) {
                        bounds.extend(this.latLng(address.location));
                        ++nbMarkers;
                    }
                });
            }

            if (bounds && this.$refs.map) {
                this.$refs.map.fitBounds(bounds);
                this.$refs.map.panToBounds(bounds);
                if (nbMarkers === 1) {
                    this.$refs.map.$mapObject.setZoom(this.zoom);
                }
            }
        }
    }

    mounted() {
        if (this.agency) {
            this.center = this.mapMarkerPosition;
        } else {
            setTimeout(() => {
                this.boundMap();
            }, 500);
        }
    }

}
