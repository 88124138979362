
import {Component, Vue, Prop} from 'vue-property-decorator';
import {mixins} from "vue-class-component";
import ProfileContextMixin from "@/components/profileContexts/ProfileContextMixin.vue";

@Component({
    name: "ProfileDescription"
})
export default class ProfileDescription extends mixins(ProfileContextMixin) {
    get theDescription() {
        return this.data.description?.replace(/\n/gm, '<br>');
    }
}
